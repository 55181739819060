import React, { useEffect } from "react";

export default function ZglosProblemPage() {
  useEffect(() => {
    window.location.href = "https://forms.gle/V5jwwwKZjnVXWh2z8";
  }, []);

  return (
    <div>
      <h2>ReportIssue</h2>
    </div>
  );
}
